<template>
  <section>
    <div class="content-header">
      <h2>Getting hired</h2>
    </div>
    <div class="content-wrapper">
      <p>Here are three great resources to share with students to help them in their career pursuits.</p>
      <div class="accordion">
        <accordion
          headerText="Employability"
          accordionID="collapse01"
        >
          <p>Professor and author Mantz Yorke defines employability as “a set of achievements – skills, understandings and personal attributes – that make graduates more likely to gain employment and be successful in their chosen occupations, which benefits themselves, the workforce, the community and the economy.”</p>
          <p>The University of Edinburgh provides a wealth of resources on its <a
              href="https://www.ed.ac.uk/employability"
              target="_blank"
            >Employability</a> site. </p>
          <p>Students can access resources to help them:</p>
          <ul>
            <li>Understand what employability is and how they can get it</li>
            <li>Develop an employability plan</li>
            <li>Access additional resources</li>
            <li>Reflect on employability, studies and life in general</li>
          </ul>
        </accordion>
        <accordion
          headerText="Get hired!"
          accordionID="collapse02"
        >
          <p>Centennial College has developed the Get Hired video series to help students understand what skills and qualities employers are looking for and how they can build these skills and qualities throughout their time at school.</p>
          <ul>
            <li><a href="https://www.youtube.com/watch?v=i08m0YVggDs" target="_blank">Get Hired</a> (0:55)</li>
            <li><a href="https://www.youtube.com/watch?v=4yrrsY0ErK8" target="_blank">Managing your Career (2:27)</a></li>
            <li><a href="https://www.youtube.com/watch?v=T0JS5z4DT8s" target="_blank">Demonstrating Professionalism</a> (2:11)</li>
            <li><a href="https://www.youtube.com/watch?v=-XbcgXpNZJQ" target="_blank">Building your Leadership Skills</a> (2:26)</li>
            <li><a href="https://www.youtube.com/watch?v=yL1Eji2UbS4" target="_blank">Maintaining Relationships</a> (2:26)</li>
            <li><a href="https://www.youtube.com/watch?v=OwnovZCprTM" target="_blank">Finding Employment in Challenging Times</a> (2:17)</li>
          </ul>
        </accordion>
        <accordion
          headerText="What does it take to be career ready?"
          accordionID="collapse03"
        >
          <p>Career readiness is a foundation from which to demonstrate requisite core competencies that broadly prepare students and graduates for success in the workplace.</p>
          <p>So what are those core competencies exactly?</p>
          <p>The National Association of Colleges and Employers has outlined eight competencies for a career-ready workforce.</p>
          <ul>
            <li>Career and self-development</li>
            <li>Communication </li>
            <li>Critical thinking </li>
            <li>Equity and inclusion </li>
            <li>Leadership </li>
            <li>Professionalism </li>
            <li>Teamwork </li>
            <li>Technology </li>
          </ul>
          <p>Learn more about what each of these competencies entails as well as how each can be demonstrated in <a
              href="https://www.naceweb.org/uploadedfiles/files/2021/resources/nace-career-readiness-competencies-revised-apr-2021.pdf"
              target="_blank"
            >Competencies for a Career-Ready Workforce</a>.</p>
            <p>Bias can influence our notions of professionalism and concept of employability. It is no accident that those who are often labelled unemployable or not job ready are also members of marginalized groups. The article, <a href="https://urldefense.com/v3/__https:/ssir.org/articles/entry/the_bias_of_professionalism_standards__;!!NCntAZiCNxG7yDGH!Ki_C3zfZaUMjA6tvFPk06jDShOw3KjuCzzmCzyPKLEWS7_JErJ2vaPof2_CbF0NMd6TPmHTQ$" target="_blank"> The Bias of ‘Professionalism’ Standards</a> challenges some of the thinking around professionalism and even commonly accepted competency frameworks. </p>
        </accordion>
      </div>
      <!-- <p class="lm">Learn more</p>
            <p>For more information about helping students foster resilience, check out this podcast and accompanying article on <a href="https://blogs.scientificamerican.com/beautiful-minds/why-academic-tenacity-matters/" target="_blank">Why Academic Tenacity Matters</a> from Scientific American.</p> -->
    </div>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '03',
  components: {
    // SidebarMenu,
    Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
